import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Header, Footer } from ".";
import { getHeading } from "../utils";
import { SiteContext } from "../context";

const ProductsCategory = () => {
  const { apiUrl, siteMaxWidth, formatAmount, slugify, products } =
    useContext(SiteContext);

  const [items, setItems] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  let navigate = useNavigate();
  let { slug } = useParams();

  useEffect(() => {
    getProducts();
    window.scrollTo(0, 0);
  }, [slug]);

  const getProducts = () => {
    let filteredProducts = products.filter(
      (item) => slugify(item.category) === slug
    );
    setItems(filteredProducts);
  };

  return (
    <div>
      <Header />

      <div style={{ maxWidth: siteMaxWidth }} className="container">
        <div className="container mt-3 px-4 py-2 bg-light">
          <Link to="/">Home</Link> / <Link to="/products">Products</Link> /{" "}
          <span>
            {slug.charAt(0).toUpperCase() +
              "" +
              slug.slice(1).split("-").join(" ")}
          </span>
        </div>

        {msg !== "" ? (
          <div className="my-2">
            <span className="p-2 my-2 border border-info text-dark">{msg}</span>
          </div>
        ) : null}

        <div className="my-1">
          <div className="row my-3 py-3">
            {items.map((item, i) => {
              return (
                <div key={i} className="col-lg-3 col-md-4 col-sm-6 text-center">
                  <button
                    className="text-decoration-none text-dark border border-light bg-light rounded py-2 m-2"
                    onClick={() =>
                      navigate(`/products/detail/${item.nameslug}`)
                    }
                  >
                    <div
                      style={{
                        position: "relative",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      <img
                        style={{ height: 250, width: 220, objectFit: "cover" }}
                        className="rounded"
                        key={item.name}
                        src={`${apiUrl}/${item.picture}`}
                        alt={item.name}
                      />
                      <div
                        style={{
                          position: "absolute",
                          padding: "10px",
                          backgroundColor: "black",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          opacity: 0.7,
                        }}
                        className="text-center"
                      >
                        <p
                          style={{ marginBottom: 15 }}
                          className="h6 text-wrap py-2 text-white"
                        >
                          {getHeading(item.name, 7)}
                        </p>
                      </div>
                    </div>
                    <div className="bg-dark d-flex justify-content-between align-items-center">
                      <div
                        style={{ borderTopRightRadius: 10 }}
                        className="bg-light py-1 px-2 text-dark"
                      >
                        {item.price !== 0 && formatAmount(item.price)}
                      </div>
                      <div className="text-center bg-warning py-1 px-2">
                        <button className="text-dark">Details</button>
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductsCategory;

import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
    category: Yup.string()
      .label('Category')
      .required(),
    name: Yup.string()
      .label('Name')
      .required()
      .min(4, 'Must have at least 4 characters'),
    description: Yup.string()
      .label('Description')
      .required()
      .min(5, 'Must have at least 11 characters'),
    price: Yup.string()
      .label('Price')
      .required('Please enter amount'),
    qty: Yup.string()
      .label('Quantity')
      .required('Please enter the quatity'),
    status: Yup.string()
      .label('Status')
      .required(),
    picture: Yup.string()
      .required()
      });
  
const ProductForm = ({ handleSubmit, setShowModal}) => {

  let navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState('');
  const [uploadedPic, setUploadedPic] =  useState(null);
  const [uploadedPic2, setUploadedPic2] =  useState(null);
  const [uploadedPic3, setUploadedPic3] =  useState(null);
  const [brands, setBrands] = useState([]);
  const [companies, setCompanies] = useState([]);

  const { apiUrl, categories } = useContext(SiteContext);

  useEffect(() => {
    getBrands();
    getCompanies();
  },[])

  const getBrands = () => {
    fetch(`${apiUrl}/api/brands.php?action=fetchall`, {
      method: 'get'
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status === 200) {
        setBrands(res.rows)
      } else if(res.status === 400) {
        setErrorMsg(res.msg);
        setTimeout(() => {
          setErrorMsg('');
        },3000)
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const getCompanies = () => {
    fetch(`${apiUrl}/api/companies.php?action=fetchall`, {
      method: 'get'
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status === 200) {
        setCompanies(res.rows)
      } else if(res.status === 400) {
        setErrorMsg(res.msg);
        setTimeout(() => {
          setErrorMsg('');
        },3000)
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

    return (
      <div>
        <div className='container'>

          {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

          <Formik
                    initialValues={{
                      id: '',
                      category: '',
                      name: '',
                      sku: '',
                      description: '',
                      price: '',
                      promo: '',
                      size: '',
                      qty: '',
                      qty_notify: '',
                      status: true,
                      picture: '',
                      picture2: '',
                      picture3: '',
                      payaccount: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                      setTimeout(() => {
                        setShowModal(false);
                      },1000);
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}  encype="multipart/form-data">
                        <div className='row'>
                          <div className='col-md-6'>

                          <div className='form-group my-1'>
                          <div>
                            <label htmlFor="category">Category</label>
                          </div>
                            <Field
                              as="select"
                              id="category"
                              name="category"
                              value={values.category}
                              placeholder="Category"
                              onBlur={handleBlur('category')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            >
                              <option value="">Select...</option>
                              {categories.map((item,i) => {
                                return (
                                  <option key={i} value={item.pcatname}>{item.pcatname}</option>
                                )
                              })}
                            </Field>

                          {touched.category && errors.category && <div className='py-1'>
                                <span className='text-danger'>{touched.category && errors.category}</span>
                          </div>}
                        </div>


                          <div className='form-group my-2'>
                          <div>
                            <label htmlFor="name">Name</label>
                          </div>
                          <Field
                            id="name"
                            name="name"
                            value={values.name}
                            placeholder="Name"
                            onBlur={handleBlur('name')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                          {touched.name && errors.name && <div className='py-1'>
                                <span className='text-danger'>{touched.name && errors.name}</span>
                          </div>}
                        </div>


                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="description">Description</label>
                            </div>
                            <Field
                              as='textarea'
                              id="description"
                              name="description"
                              value={values.description}
                              placeholder="Description"
                              onBlur={handleBlur('description')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            row='5'
                            />
                          {touched.description && errors.description && <div className='py-1'>
                                <span className='text-danger'>{touched.description && errors.description}</span>
                          </div>}
                        </div>


                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="picture"> Picture</label>
                          </div>
                          <input id="picture" name="picture" type="file" onChange={(event) => {
                                 setFieldValue("picture", event.currentTarget.files[0]);
                                 setUploadedPic(event.currentTarget.files[0]);
                                }} />
                        </div>

                        {uploadedPic && <img className='my-2' src={URL.createObjectURL(uploadedPic)} class="img-fluid mt-2" width={80} height={80} id="output_image"/>}

                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="picture2"> Picture 2</label>
                          </div>
                          <input id="picture2" name="picture2" type="file" onChange={(event) => {
                                 setFieldValue("picture2", event.currentTarget.files[0]);
                                 setUploadedPic2(event.currentTarget.files[0]);
                                }} />
                        </div>

                        {uploadedPic2 && <img className='my-2' src={URL.createObjectURL(uploadedPic2)} class="img-fluid mt-2" width={80} height={80} id="output_image2"/>}

                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="picture3"> Picture 3</label>
                          </div>
                          <input id="picture3" name="picture3" type="file" onChange={(event) => {
                                 setFieldValue("picture3", event.currentTarget.files[0]);
                                 setUploadedPic3(event.currentTarget.files[0]);
                                }} />
                        </div>

                        {uploadedPic3 && <img className='my-2' src={URL.createObjectURL(uploadedPic3)} class="img-fluid mt-2" width={80} height={80} id="output_image3"/>}

                          </div>
                          <div className='col-md-6'>

                            
                          <div className='form-group my-1'>
                          <div>
                            <label htmlFor="price">Price</label>
                          </div>
                            <Field
                              id="price"
                              name="price"
                              value={values.price}
                              placeholder="Price"
                              onBlur={handleBlur('price')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            />
                          {touched.price && errors.price && <div className='py-1'>
                                <span className='text-danger'>{touched.price && errors.price}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="promo">Promo</label>
                          </div>
                            <Field
                              id="promo"
                              name="promo"
                              value={values.promo}
                              placeholder="Promo"
                              onBlur={handleBlur('promo')}
                              autoCapitalize="none"
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            />
                          {touched.promo && errors.promo && <div className='py-1'>
                                <span className='text-danger'>{touched.promo && errors.promo}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="sku">SKU</label>
                          </div>
                          <Field
                            id="sku"
                            name="sku"
                            value={values.sku}
                            placeholder="SKU"
                            onBlur={handleBlur('sku')}
                            autoCapitalize="none"
                            style={{
                              padding: 10
                          }}
                          className="form-control"
                          />
                            {touched.sku && errors.sku && <div className='py-1'>
                                <span className='text-danger'>{touched.sku && errors.sku}</span>
                            </div>}
                          </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="size">Weight</label>
                          </div>
                            <Field
                              id="size"
                              name="size"
                              value={values.size}
                              placeholder="Weight"
                              onBlur={handleBlur('size')}
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            />
                          
                          {touched.size && errors.size && <div className='py-1'>
                                <span className='text-danger'>{touched.size && errors.size}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="qty">Quantity</label>
                          </div>
                            <Field
                              id="qty"
                              name="qty"
                              value={values.qty}
                              placeholder="Quantity"
                              onBlur={handleBlur('qty')}
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            />
                          
                          {touched.qty && errors.qty && <div className='py-1'>
                                <span className='text-danger'>{touched.qty && errors.qty}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="qty_notify">Notification Threshhold </label>
                          </div>
                            <Field
                              id="qty_notify"
                              name="qty_notify"
                              value={values.qty_notify}
                              placeholder="Notification threshhold"
                              onBlur={handleBlur('qty_notify')}
                              style={{
                                padding: 10
                            }}
                            className="form-control"
                            />
                          
                          {touched.qty_notify && errors.qty_notify && <div className='py-1'>
                                <span className='text-danger'>{touched.qty_notify && errors.qty_notify}</span>
                          </div>}
                        </div>


                        <div className='form-group my-1 py-2'>
                          <div>
                            <label htmlFor="status">In stock?</label> <Field type="checkbox" name="status" /> {values.status ? 'Yes' : 'No'}
                          </div>
                        </div>


                          </div>
                        </div>
                         
                         
                          <button
                            type='submit'
                            onClick={handleSubmit}
                            title="Add"
                            disabled={!isValid || isSubmitting}
                              className='btn btn-primary my-2'
                          >
                              Add
                          </button>

                          
                    <button
                      onClick={() => navigate(-1)}
                      title="Cancel"
                      className='btn btn-secondary mx-2'
                    >
                      Cancel
                    </button>
                        
                          </form>
                    )}
                  </Formik>
      </div>
    </div>
    )
}

export default ProductForm;
import React, { useContext, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ReactModal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import { Cart, CategoriesMenu, SearchBox } from "../components";
import { SiteContext } from "../context/index";

const Header = () => {
  const {
    isLoggedOn,
    siteMaxWidth,
    handleSignOut,
    discounts,
    theme,
    handleTheme,
    slugify,
    categories,
    currency,
    handleCurrency,
    getOrderItemsTotal,
    currentPage,
    handleCurrentPage,
    apiUrl,
    siteconfig,
    isMobile,
    singleProduct,
  } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [showModal, setShowModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [showProdCat, setShowProdCat] = useState(false);
  const [page, setPage] = useState("");
  const [mType, setMType] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    let filtered = discounts.filter(
      (item) => item.discountdesc !== "New sign up"
    );
    setDiscount(filtered[0]);
  }, []);

  useEffect(() => {
    if (currentPage !== "") setPage(currentPage);
  }, [currentPage]);

  const doSignOut = () => {
    navigate("/");
    handleSignOut();
  };

  const switchModal = (item) => {
    if (item === "menu") {
      setMType("menu");
    } else if (item === "cart") {
      setMType("cart");
    } else if (item === "search") {
      setMType("search");
    } else if (item === "user") {
      setMType("user");
    }
    setShowModal(true);
  };

  const HeaderMenu = () => {
    return (
      <div className="w-100 h-100 px-3">
        <div style={{ backgroundColor: "#a17455" }}>
          <Link
            className="p-2 btn w-100 rounded-0 text-start border-bottom text-white"
            to="/"
            onClick={() => setShowMenu(!showMenu)}
          >
            Home
          </Link>
        </div>
        <div style={{ backgroundColor: "#a17455" }}>
          <span
            className="p-2 btn w-100 rounded-0 text-start border-bottom text-white"
            onClick={() => {
              setShowProdCat(!showProdCat);
            }}
          >
            Products <i className="bi bi-chevron-down px-1"></i>
          </span>
        </div>
        {showProdCat && (
          <div>
            {categories.map((item, i) => {
              return (
                <div style={{ backgroundColor: "#d19064" }} key={i}>
                  <Link
                    className="btn w-100 border-bottom text-decoration-none text-white text-start"
                    onClick={() => setShowMenu(!showMenu)}
                    to={`/products/category/${slugify(item)}`}
                  >
                    {item}
                  </Link>
                </div>
              );
            })}
          </div>
        )}
        <div style={{ backgroundColor: "#a17455" }}>
          <Link
            className="p-2 btn w-100 rounded-0 text-start border-bottom text-white"
            to="/photo-gallery"
            onClick={() => setShowMenu(!showMenu)}
          >
            Gallery
          </Link>
        </div>
        <div style={{ backgroundColor: "#a17455" }}>
          <Link
            className="p-2 btn w-100 rounded-0 text-start border-bottom text-white"
            to="/blog"
            onClick={() => setShowMenu(!showMenu)}
          >
            Blog
          </Link>
        </div>
        <div style={{ backgroundColor: "#a17455" }}>
          <Link
            className="p-2 btn w-100 rounded-0 text-start border-bottom text-white"
            to="/page/about"
            onClick={() => setShowMenu(!showMenu)}
          >
            About
          </Link>
        </div>
        <div style={{ backgroundColor: "#a17455" }}>
          <Link
            className="p-2 btn w-100 rounded-0 text-start border-bottom text-white"
            to="/contact"
            onClick={() => setShowMenu(!showMenu)}
          >
            Contact
          </Link>
        </div>
        <div style={{ backgroundColor: "#a17455" }}>
          <Link
            className="p-2 btn w-100 rounded-0 text-start border-bottom text-white"
            onClick={() => {
              switchModal("search");
              setShowMenu(!showMenu);
            }}
            to=""
          >
            Search
          </Link>
        </div>
        {isLoggedOn && (
          <div style={{ backgroundColor: "#a17455" }}>
            <Link
              className="p-2 btn w-100 rounded-0 text-start border-bottom text-white"
              onClick={() => doSignOut()}
              to=""
            >
              Log out
            </Link>
          </div>
        )}
      </div>
    );
  };

  const UserMenu = () => {
    return (
      <div>
        {userData ? (
          <h4 className="py-1 border-bottom">Hello {userData.name}</h4>
        ) : (
          <h4 className="py-1 border-bottom">Hello Guest</h4>
        )}
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/user">
            My Account
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div>
      {showModal && (
        <div
          style={{ position: "fixed", top: 0, left: 0, zIndex: 150 }}
          className="w-100 h-100 bg-dark bg-opacity-75"
        />
      )}
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: isMobile ? 0 : "20%",
            right: isMobile ? 0 : "20%",
            width: isMobile ? "100%" : "60%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
            zIndex: 200,
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "10px",
          },
        }}
      >
        <div
          className={mType === "menu" ? "w-75 h-100 bg-dark" : "w-100 h-100"}
        >
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-danger mb-2 py-1"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x h3 text-white"></i>
            </span>
          </div>
          {mType === "cart" && <Cart setShowModal={setShowModal} />}{" "}
          {mType === "search" && <SearchBox />}{" "}
          {mType === "user" && <UserMenu />}
        </div>
      </ReactModal>

      {showMenu && (
        <div
          style={{ position: "fixed", top: 0, left: 0, zIndex: 400 }}
          className="w-100 h-100 bg-dark bg-opacity-75"
        ></div>
      )}

      {showMenu ? (
        <div
          style={{
            backgroundColor: "#d19064",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 500,
          }}
          className="w-[300px] h-100"
        >
          <div className="d-flex justify-content-between align-items-center">
            <img
              className="p-3"
              src={`${apiUrl}/${siteconfig.shoplogo}`}
              height={isMobile ? 50 : 120}
              width={isMobile ? 140 : 160}
              alt="Logo"
            />
            <span
              className="btn btn-light btn-sm m-2 p-2"
              onClick={() => setShowMenu(false)}
            >
              <i className="bi bi-x h3 text-danger"></i>
            </span>
          </div>
          <HeaderMenu />
        </div>
      ) : (
        <div
          style={{ position: "fixed", top: 0, left: "-100%", zIndex: 400 }}
          className="w-100 h-100 bg-dark bg-opacity-75"
        ></div>
      )}

      <div
        style={{
          backgroundColor: theme.backgroundColor,
          color: theme.color,
          height: isMobile ? 120 : currentPage === "home" ? 160 : 160,
        }}
      >
        {discount && (
          <div className="bg-warning bg-opacity-25 text-dark text-center p-1">
            {`${discount.discountdesc} - ${discount.discountpercent}% Off - Use ${discount.discountcode} at checkout`}{" "}
            <i className="bi bi-arrow-right"></i>
          </div>
        )}
        <div style={{ maxWidth: siteMaxWidth }} className="mx-auto">
          <div className="row px-1 py-2">
            <div className="col-lg-3 col-4">
              <div className="d-flex justify-content-start aligh-items-center">
                <button
                  className={"px-1 d-none d-lg-block"}
                  onClick={() => setShowMenu(true)}
                >
                  <i
                    className={
                      theme.type === "light"
                        ? "bi bi-list h1 text-dark"
                        : "bi bi-list h1 text-white"
                    }
                  ></i>
                </button>
                <Link className="inline-block p-2" to="/">
                  <img
                    src={`${apiUrl}/${siteconfig.shoplogo}`}
                    height={isMobile ? 50 : 120}
                    width={isMobile ? 140 : 160}
                    alt="Logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-8 d-flex justify-content-end align-items-center">
              <div className="col-lg-12 d-lg-block d-none">
                <div className="row">
                  <div className="col-lg-7 d-flex justify-content-end">
                    <div className="row">
                      <div className={isMobile ? "col-1" : "col-2"}>
                        <i className="bi bi-phone text-center h2"></i>
                      </div>
                      <div className="col small">
                        <span className="text-muted fst-italic">
                          CUSTOMER SUPPORT & SALES
                        </span>
                        <br />
                        <span className="h6">{siteconfig.shopphone}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="d-flex justify-content-end align-content-center">
                      <div className="small py-1">
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span className="small">{currency}</span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleCurrency("N")}>
                              N
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleCurrency("$")}>
                              $
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleCurrency("£")}>
                              £
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      {!singleProduct && (
                        <button
                          style={{
                            position: "static",
                            color: theme.type === "light" ? "black" : "white",
                          }}
                          className="px-3 py-2 mx-2 text-decoration-none rounded-pill bg-dark text-white"
                          onClick={() => navigate("/cart")}
                        >
                          <span className="inline-block h6">
                            Cart <i className="bi bi-cart3 h6"></i>
                          </span>
                          <span
                            style={{ position: "relative", top: -7, right: 2 }}
                            className="text-white badge bg-danger p-1 m-0 rounded-circle small"
                          >
                            {getOrderItemsTotal()}
                          </span>
                        </button>
                      )}
                      <button
                        style={{ height: 40, width: 40 }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`Change theme to ${
                          theme.type === "light" ? "black" : "white"
                        }`}
                        className={
                          theme.type === "light"
                            ? "my-1 px-2 py-1 text-dark bg-white border border-dark rounded-circle"
                            : "my-1 px-2 py-1 text-white bg-dark border border-white rounded-circle"
                        }
                        onClick={() => handleTheme(theme.type)}
                      >
                        {theme.type === "light" ? (
                          <i className="bi bi-moon"></i>
                        ) : (
                          <i className="bi bi-moon"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block d-lg-none d-flex justify-content-end align-items-center">
                <button className={"px-3"} onClick={() => setShowMenu(true)}>
                  <i
                    className={
                      theme.type === "light"
                        ? "bi bi-list h1 text-dark"
                        : "bi bi-list h1 text-white"
                    }
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#a17455" }}
          className="d-lg-block d-none d-flex align-content-center"
        >
          <div
            style={{
              bottom: 2,
              paddingTop: page === "home" ? "15px" : "10px",
              height: 60,
              zIndex: 100,
              maxWidth: siteMaxWidth,
            }}
            className="mx-auto rounded-2 text-white"
          >
            <div className="row">
              <div className="col-lg-9">
                <div className=" ">
                  <div className="d-none d-lg-block">
                    {page !== "home" && (
                      <div className="inline-block">
                        <Dropdown>
                          <Dropdown.Toggle variant="dark" id="dropdown-basic">
                            <i className="bi bi-list-ul"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-[300px]">
                            <Dropdown.Item>
                              <CategoriesMenu />
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                    <Link
                      className={
                        page === "home"
                          ? "py-2 px-2 text-decoration-none bg-white text-dark rounded"
                          : "py-2 px-2 text-decoration-none text-white"
                      }
                      onClick={() => handleCurrentPage("home")}
                      to="/"
                    >
                      HOME
                    </Link>
                    <div
                      className={
                        page === "products"
                          ? "inline-block bg-white rounded py-1 px-2"
                          : "inline-block rounded py-1 px-2"
                      }
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="transparent"
                          id="dropdown-basic"
                        >
                          <Link
                            className={
                              page === "products"
                                ? "text-decoration-none bg-white text-dark rounded px-1 h6"
                                : "text-decoration-none text-white px-1 h6"
                            }
                            onClick={() => handleCurrentPage("products")}
                            to="/products"
                          >
                            PRODUCTS
                          </Link>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-[200px] border-0">
                          {categories.map((item, i) => {
                            return (
                              <Dropdown.Item key={i}>
                                <Link
                                  className="text-decoration-none text-dark"
                                  onClick={() => handleCurrentPage("products")}
                                  to={`/products/category/${slugify(item)}`}
                                >
                                  {item}
                                </Link>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <Link
                      className={
                        page === "about"
                          ? "py-2 px-2 text-decoration-none bg-white text-dark rounded"
                          : "py-2 px-2 text-decoration-none text-white"
                      }
                      onClick={() => handleCurrentPage("about")}
                      to="/page/about"
                    >
                      ABOUT
                    </Link>
                    <Link
                      className={
                        page === "photos"
                          ? "py-2 px-2 text-decoration-none bg-white text-dark rounded"
                          : "py-2 px-2 text-decoration-none text-white"
                      }
                      onClick={() => handleCurrentPage("photos")}
                      to="/photo-gallery"
                    >
                      GALLERY
                    </Link>
                    <Link
                      className={
                        page === "blog"
                          ? "py-2 px-2 text-decoration-none bg-white text-dark rounded"
                          : "py-2 px-2 text-decoration-none text-white"
                      }
                      onClick={() => handleCurrentPage("blog")}
                      to="/blog"
                    >
                      BLOG
                    </Link>
                    <Link
                      className={
                        page === "contact"
                          ? "py-2 px-2 text-decoration-none bg-white text-dark rounded"
                          : "py-2 px-2 text-decoration-none text-white"
                      }
                      onClick={() => handleCurrentPage("contact")}
                      to="/contact"
                    >
                      CONTACT
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 py-0 my-0">
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    className="px-2 py-0 text-decoration-none btn btn-transparent btn-sm text-white"
                    onClick={() => switchModal("search")}
                  >
                    <i className="bi bi-search h3"></i>
                  </button>
                  {isLoggedOn ? (
                    <div className="px-1 py-0 my-0">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="transparent"
                          id="dropdown-basic"
                        >
                          <span className="h-[25px], w-[25px]">
                            <img
                              src={`${apiUrl}/${userData.photo}`}
                              height={20}
                              width={20}
                              className="inline-block rounded-circle"
                              alt={userData.name}
                            />{" "}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <h6 className="border-bottom">
                              Hello {userData.name}
                            </h6>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/user")}>
                            Account
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => doSignOut()}>
                            <i className="bi bi-box-arrow-right h6"></i> Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <div className="px-1 py-0 my-0">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="transparent"
                          id="dropdown-basic"
                        >
                          <i className="bi bi-person"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <h6 className="border-bottom">Hello Guest</h6>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/login")}>
                            Login
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/signup")}>
                            Signup
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: theme.type === "light" ? "#d19064" : "white",
            color: theme.type === "light" ? "white" : "black",
          }}
          className="d-block d-lg-none p-1"
        >
          <div className="d-flex justify-content-around align-content-center">
            <button
              style={{ height: 40, width: 40 }}
              data-toggle="tooltip"
              data-placement="bottom"
              title={`Change theme to ${
                theme.type === "light" ? "black" : "white"
              }`}
              className={
                theme.type === "light"
                  ? "px-2 text-dark bg-white rounded-circle"
                  : "px-2 text-white bg-dark rounded-circle"
              }
              onClick={() => handleTheme(theme.type)}
            >
              {theme.type === "light" ? (
                <i className="bi bi-moon"></i>
              ) : (
                <i className="bi bi-moon"></i>
              )}
            </button>

            <div className="py-1">
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {currency}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleCurrency("N")}>
                    N
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleCurrency("$")}>
                    $
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleCurrency("£")}>
                    £
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {!singleProduct && (
              <button
                style={{
                  position: "static",
                  color: theme.type === "light" ? "white" : "black",
                }}
                type="button"
                className="px-2 text-decoration-none btn btn-transparent btn-sm"
                onClick={() => navigate("/cart")}
              >
                <i className="bi bi-cart3 h3"></i>
                <span
                  style={{ position: "relative", top: -15, right: 7 }}
                  className="text-white badge bg-danger p-1 m-0 rounded-circle small"
                >
                  {getOrderItemsTotal()}
                </span>
              </button>
            )}
            <button
              style={{ color: theme.type === "light" ? "white" : "black" }}
              className="px-2 text-decoration-none btn btn-transparent"
              onClick={() => switchModal("search")}
            >
              <i className="bi bi-search h2"></i>
            </button>

            {isLoggedOn ? (
              <div className="px-1 py-0">
                <Dropdown>
                  <Dropdown.Toggle variant="warning" id="dropdown-basic">
                    {userData !== null ? (
                      <span>
                        <img
                          src={`${apiUrl}/${userData.photo}`}
                          height={20}
                          width={20}
                          className="inline-block rounded-circle"
                          alt={userData.name}
                        />{" "}
                      </span>
                    ) : (
                      <i className="bi bi-person"></i>
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      {userData ? (
                        <h6 className="border-bottom">Hello {userData.name}</h6>
                      ) : (
                        <h6 className="border-bottom">Hello Guest</h6>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/user")}>
                      Account
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => doSignOut()}>
                      <i className="bi bi-box-arrow-right h6"></i> Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <div className="px-1 py-0">
                <Dropdown>
                  <Dropdown.Toggle variant="warning" id="dropdown-basic">
                    <i className="bi bi-person"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <h6 className="border-bottom">Hello Guest</h6>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/login")}>
                      Login
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/signup")}>
                      Signup
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { SiteContext } from "../context";

const CategoriesMenu = () => {
  const { categories, handleCurrentPage, slugify, theme } =
    useContext(SiteContext);

  let navigate = useNavigate();

  return (
    <div className="bg-warning bg-opacity-75 m-0 p-0">
      <h5 className="bg-dark bg-opacity-75 rounded-top my-0 py-3 text-white px-2">
        CATEGORIES
      </h5>

      {categories.length > 0 && (
        <div>
          {categories.map((item, i) => {
            return (
              <div key={i} className="bg-secondary bg-opacity-75 border-bottom">
                <Link
                  style={{ color: "#fff" }}
                  className="btn btn-transparent text-start w-100 text-decoration-none h6"
                  to={`/products/category/${slugify(item)}`}
                  onClick={() => handleCurrentPage("products")}
                >
                  {item}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CategoriesMenu;

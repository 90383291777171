import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";

const PopularProducts = () => {
  const { apiUrl, isMobile, formatAmount, slugify, products } =
    useContext(SiteContext);

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  let navigate = useNavigate();

  return (
    <div className={isMobile ? "container-fluid" : "container"}>
      <div className="my-3 py-2">
        <div className="py-2 h2 text-white">Popular Products</div>
        <div className="relative flex items-center">
          <div
            id="slider"
            className="w-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide"
          >
            {products
              .sort((a, b) => parseInt(a.hits) - parseInt(b.hits))
              .reverse()
              .slice(0, 4)
              .map((item) => {
                return (
                  <button
                    onClick={() =>
                      navigate(`/products/detail/${item.nameslug}`)
                    }
                    className="w-[300px] inline-block text-start cursor-pointer p-2"
                  >
                    <div
                      style={{
                        position: "relative",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                      className="d-flex justify-content-center align-content-center"
                    >
                      <img
                        style={{
                          height: 280,
                          width: 260,
                          objectFit: "contain",
                        }}
                        key={item.name}
                        src={`${apiUrl}/${item.picture}`}
                        alt={item.name}
                      />
                    </div>
                    <div style={{ padding: "10px" }} className="text-center">
                      <p
                        style={{ marginBottom: 5 }}
                        className="h4 text-wrap py-2 text-white"
                      >
                        {item.name}
                      </p>
                    </div>
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularProducts;

import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavigationBar } from ".";
import { SiteContext } from "../context";

const AllProducts = () => {
  const { apiUrl, formatAmount, categories, products } =
    useContext(SiteContext);

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  let navigate = useNavigate();

  return (
    <div>
      {msg !== "" ? (
        <div className="my-2">
          <span className="p-2 my-2 border border-info text-dark">{msg}</span>
        </div>
      ) : null}

      {categories
        .sort((a, b) => a.pcid - b.pcid)
        .map((item) => {
          const cat = item;
          return (
            <div className="my-3">
              <div
                style={{ backgroundColor: "#a17455" }}
                className="m-0 px-2 py-2 h4 d-flex justify-content-between align-items-center"
              >
                <div className="text-white">{cat}</div>
                <div className="h6">
                  <Link
                    className="text-decoration-none text-white"
                    to={`/products/category/${item.pcatslug}`}
                  >{`SEE ALL > `}</Link>
                </div>
              </div>
              <div className="relative flex items-center border">
                <div
                  id="slider"
                  className="w-full h-[270px] overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide"
                >
                  {products
                    .filter((item) => item.category === cat)
                    .map((item) => {
                      return (
                        <button
                          onClick={() =>
                            navigate(`/products/detail/${item.nameslug}`)
                          }
                          className="w-[220px] inline-block text-start cursor-pointer hover:scale-105 ease-in-out duration-300 p-2"
                        >
                          <div
                            style={{
                              position: "relative",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            }}
                          >
                            <img
                              style={{
                                height: 250,
                                width: 220,
                                objectFit: "contain",
                              }}
                              key={item.name}
                              src={`${apiUrl}/${item.picture}`}
                              alt={item.name}
                            />
                            <div
                              style={{
                                position: "absolute",
                                padding: "10px",
                                backgroundColor: "black",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                opacity: 0.7,
                              }}
                              className="text-center"
                            >
                              <p
                                style={{ marginBottom: 20 }}
                                className="h6 text-wrap py-2 text-white"
                              >
                                {item.name}
                              </p>
                              <span
                                style={{
                                  backgroundColor: "white",
                                  position: "absolute",
                                  right: 0,
                                  bottom: 0,
                                  paddingTop: 4,
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  borderTopLeftRadius: 10,
                                }}
                                className="text-dark"
                              >
                                {formatAmount(item.price)}
                              </span>
                            </div>
                          </div>
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default AllProducts;
